<template>
  <div class="c-recipe-ingredients">
    <h2>{{ model.ingredientsHeading }}</h2>

    <!-- Portion selector -->
    <template v-if="portions">
      <span
        v-if="!model.ingredients.amountIsScalable"
        class="c-recipe-ingredients__portion--fixed"
      >
        {{ portions }} {{ amountText }}
      </span>

      <RecipeIngredientsPortions
        v-else
        :model="model"
        class="u-mb--m u-mt--m"
      />
    </template>

    <!-- Ingredient list -->
    <div class="c-recipe-ingredients__list">
      <recipe-ingredient-group
        v-for="ingredientGroup in scaledIngredientsGroups"
        :key="ingredientGroup.index"
        :model="ingredientGroup"
      />
    </div>

    <!-- Actions -->
    <RecipeActions :model="model" />
  </div>
</template>

<script>
import { computed } from 'vue'
import RecipeActions from '@/components/recipe/RecipeActions.vue'
import RecipeIngredientGroup from '@/components/recipe/RecipeIngredientGroup.vue'
import RecipeIngredientsPortions from '@/components/recipe/RecipeIngredientsPortions.vue'
import ingredientScaleHelper from '@/composition/recipeIngredientsScaleHelper'

export default {
  components: {
    RecipeIngredientsPortions,
    RecipeActions,
    RecipeIngredientGroup
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { model } = props
    const { state } = ingredientScaleHelper(model)
    const portions = computed(() => state.portions)

    return {
      portions,
      amountText: computed(() => state.amountText),
      scaledIngredientsGroups: computed(() => state.ingredientGroups)
    }
  }
}
</script>
