<template>
  <div class="u-pt--l u-pb--l">
    <div class="o-container">
      <h2 class="c-search__header u-mb--xs u-font-size-h3">
        {{ searchConfiguration.translations.title }}
      </h2>
    </div>

    <SearchHeader />
    <SearchItems :initial-search-result="initialSearchResult" />
  </div>
</template>

<script>
import { watch } from 'vue'
import SearchHeader from '@/components/search/SearchHeader.vue'
import SearchItems from '@/components/search/SearchItems.vue'
import searchQueryState from '@/composition/search/searchQuery'
import searchConfig from '@/composition/search/searchConfig'
import routerHelper from '@/composition/search/searchRouterHelper'

export default {
  components: { SearchHeader, SearchItems },
  props: {
    searchConfiguration: {
      type: Object,
      required: true
    },
    initialSearchResult: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    }
  },
  setup(props) {
    searchConfig.searchConfigurationUse(props.searchConfiguration, props.baseUrl)

    const { getRouteData, updateRoute } = routerHelper.useRouterHelper(props.baseUrl)
    const routeData = getRouteData()
    routeData.language = props.language
    const { searchResult } = searchQueryState.searchQueryUse(routeData)

    watch(
      () => [searchResult.loading],
      () => {
        if (!searchResult.loading) {
          updateRoute()
        }
      }
    )
  }
}
</script>
