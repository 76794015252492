import { computed, reactive } from 'vue'
import { formatFractionNumber } from '@/composition/fractionFormatter'

const portionsState = reactive({
  portions: null,
  basePortions: null,
  amountText: '',
  ingredientGroups: []
})

export default (ingredientsModel) => {
  const {
    ingredients,
    language,
    emptyUnit,
    arrayOfUnitNamesWithoutSpaces
  } = ingredientsModel

  const state = portionsState
  state.portions = ingredients.amountUnit ? ingredients.amountUnit.amount : null
  state.basePortions = ingredients.amountUnit ? ingredients.amountUnit.amount : null

  const amountIsSingular = (amount) => (amount != null && amount <= 1)

  const amountIsFraction = (amount) => (amount != null && amount % 1 !== 0)

  const amountIsNonFractionPlural = (amount) => {
    const floatAmount = parseFloat(amount)
    return !amountIsSingular(floatAmount) && !amountIsFraction(floatAmount)
  }

  const calculateUnitAmount = (unitItem) => {
    const unit = { ...unitItem }

    if (!unit || unit.amount === 0) {
      return unit
    }

    const hasBasePortions = state.basePortions != null
    let calcAmount = Number(unit.amount)
    let recalculate = false
    if (hasBasePortions) {
      calcAmount = (unit.amount * state.portions) / state.basePortions

      if (unit.singularName === 'g' && calcAmount >= 1000) {
        unit.amount /= 1000
        unit.singularName = 'kg'
        unit.pluralName = 'kg'
        recalculate = true
      } else if (unit.singularName === 'kg' && calcAmount < 1) {
        unit.amount *= 1000
        unit.singularName = 'g'
        unit.pluralName = 'g'
        recalculate = true
      } else if (unit.singularName === 'dl' && calcAmount >= 10) {
        unit.amount /= 10
        unit.singularName = 'l'
        unit.pluralName = 'l'
        recalculate = true
      } else if (unit.singularName === 'l' && calcAmount < 1) {
        unit.amount *= 10
        unit.singularName = 'dl'
        unit.pluralName = 'dl'
        recalculate = true
      }
    }

    if (recalculate) {
      unit.amount = hasBasePortions ? (unit.amount * state.portions) / state.basePortions : unit.amount
    } else {
      unit.amount = calcAmount
    }

    return unit
  }

  const getIngredientUnitName = (unit) => {
    if (unit.singularName === emptyUnit || unit.unitType === 'number') {
      return ''
    }

    if (unit.amount <= 1 || unit.pluralName == null || unit.pluralName === '') {
      return unit.singularName
    }

    return unit.pluralName
  }

  const getIngredientName = (ingredient, unit) => {
    if (ingredient.singularName == null || ingredient.singularName === '') {
      return ingredient.pluralName
    }

    if (ingredient.pluralName == null || ingredient.pluralName === '') {
      return ingredient.singularName
    }

    if (ingredient.unit.singularName === emptyUnit) {
      return ingredient.pluralName
    }

    const simpleTypes = ['volume', 'weight', 'piece', 'other']
    if (simpleTypes.indexOf(ingredient.unit.unitType) !== -1) {
      return ingredient.massNoun ? ingredient.singularName : ingredient.pluralName
    }

    if (unit.unitType === 'number') {
      return amountIsNonFractionPlural(unit.amount) ? ingredient.pluralName : ingredient.singularName
    }

    return ingredient.singularName
  }

  const getConcatedString = (str1, str2) => {
    if (str1 == null || str1 === '') {
      return str2
    }
    if (str2 == null || str2 === '') {
      return str1
    }
    if (str2.startsWith(',')) {
      return str1.concat(str2)
    }
    if (arrayOfUnitNamesWithoutSpaces && arrayOfUnitNamesWithoutSpaces.indexOf(str2) > -1) {
      return str1.concat(str2)
    }
    return str1.concat(' ', str2)
  }

  const getIngredientAmount = (unit) => {
    if (unit.amount === 0 || unit.singularName === emptyUnit) {
      return ''
    }
    return formatFractionNumber(unit.amount, language)
  }

  const getFullSecondaryUnitName = (unit) => {
    if (!unit) return ''

    let unitText = ''
    const recalculatedUnit = calculateUnitAmount(unit)

    unitText = getConcatedString(unitText, getIngredientAmount(recalculatedUnit))
    unitText = getConcatedString(unitText, getIngredientUnitName(recalculatedUnit))
    unitText = getConcatedString(unitText, recalculatedUnit.postfix)

    return unitText
  }

  const getFullIngredientName = (ingredient) => {
    let ingredientName = ''
    let ingredientUnitName = ''

    const recalculatedUnit = calculateUnitAmount(ingredient.unit)

    ingredientName = getConcatedString(ingredientName, ingredient.prefix)
    ingredientName = getConcatedString(ingredientName, getIngredientName(ingredient, recalculatedUnit))
    ingredientName = getConcatedString(ingredientName, ingredient.postfix)

    ingredientUnitName = getConcatedString(ingredientUnitName, recalculatedUnit.prefix)
    ingredientUnitName = getConcatedString(ingredientUnitName, getIngredientAmount(recalculatedUnit))
    ingredientUnitName = getConcatedString(ingredientUnitName, getIngredientUnitName(recalculatedUnit))
    return { ingredientUnitName, ingredientName }
  }

  function setPortions(portions) {
    state.portions = portions
  }

  state.ingredientGroups = ingredients.ingredientsGroups.map((group) => ({
    name: group.name,
    index: group.index,
    items: group.items.map((ingredient) => ({
      name: computed(() => getFullIngredientName(ingredient)),
      product: ingredient.product,
      secondaryUnit: computed(() => getFullSecondaryUnitName(ingredient.secondaryUnit))
    }))
  }))

  state.amountText = computed(() => {
    let amountUnitText = ''
    if (state.portions <= 1) {
      amountUnitText = ingredients.amountUnit.singularName
    } else {
      amountUnitText = ingredients.amountUnit.pluralName
    }
    return amountUnitText
  })

  return {
    state,
    setPortions
  }
}
