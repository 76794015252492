function calculateCrop(width, height, model) {
  const sourceWidth = model.width
  const sourceHeight = model.height

  if (sourceWidth == 0
      || sourceHeight == 0
      || !model.focusPointX
      || !model.focusPointY) {
    return null
  }

  const focalPointY = Math.round(sourceHeight * (model.focusPointY / 100))
  const focalPointX = Math.round(sourceWidth * (model.focusPointX / 100))
  const sourceAspectRatio = sourceWidth / sourceHeight

  // Calculate target aspect ratio from resizeSettings.
  let targetAspectRatio
  if (width > 0 && height > 0) {
    targetAspectRatio = width / height
  } else {
    targetAspectRatio = sourceAspectRatio
  }

  let x1 = 0
  let y1 = 0
  let x2
  let y2

  if (targetAspectRatio == sourceAspectRatio) {
    x2 = sourceWidth
    y2 = sourceHeight
  } else if (targetAspectRatio > sourceAspectRatio) {
    // the requested aspect ratio is wider than the source image
    const newHeight = Math.floor(sourceWidth / targetAspectRatio)
    x2 = sourceWidth
    y1 = Math.max(focalPointY - Math.round(newHeight / 2), 0)
    y2 = Math.min(y1 + newHeight, sourceHeight)
    if (y2 == sourceHeight) {
      y1 = y2 - newHeight
    }
  } else {
    // the requested aspect ratio is narrower than the source image
    const newWidth = Math.round(sourceHeight * targetAspectRatio)
    x1 = Math.max(focalPointX - Math.round(newWidth / 2), 0)
    x2 = Math.min(x1 + newWidth, sourceWidth)
    y2 = sourceHeight
    if (x2 == sourceWidth) {
      x1 = x2 - newWidth
    }
  }

  return `${x1},${y1},${x2},${y2}`
}

export default {
  // Copy of c# code from ImageResizingNetImageResizer.Resize method
  ImageResizingNet: {
    resize(src, width, height, options) {
      if (src == null) {
        return null
      }

      let url
      let crop = null

      if (src.url != null) {
        url = src.url
        if (options?.mode === 'crop') {
          crop = calculateCrop(width, height, src)
        }
      } else {
        url = src
      }

      const qs = {}
      const queryIndex = url.indexOf('?')

      if (queryIndex >= 0) {
        const queryString = url.substring(queryIndex)
        queryString.split('&').forEach((part) => {
          const item = part.split('=')
          qs[item[0]] = decodeURIComponent(item[1])
        })

        url = url.substring(0, queryIndex)
      }

      if (width > 0) {
        qs.width = width
      }

      if (height > 0) {
        qs.height = height
      }

      qs.mode = options?.mode ?? 'crop'
      if (options != null) {
        qs.preset = options.preset
      } else {
        qs.format = 'webp'
      }

      if (crop !== null) {
        qs.crop = crop
      }

      const queryParams = []
      Object.entries(qs).forEach(([key, value]) => {
        queryParams.push(`${key}=${value}`)
      })

      const queryString = queryParams.join('&')
      return `${url}?${queryString}`
    }
  }
}
