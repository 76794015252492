<template>
  <div class="o-container">
    <div
      v-if="hasExtendedFilters && !loading"
      class="u-mb--s u-font-size-large"
    >
      {{ searchResult.totalCount }} {{ translations.totalCountMessage }}
    </div>

    <!-- Results list -->
    <component
      :is="gridComponent"
      :items="searchResult.items"
    />

    <!-- Search more -->
    <div>
      <div
        v-if="!loading && !allItemsLoaded"
        class="u-flex u-flex-justify-center u-mt--m"
      >
        <button
          class="c-button"
          @click="searchMoreResults"
        >
          {{ translations.loadMoreButtonText }}
        </button>
      </div>
    </div>

    <!-- Empty state -->
    <div
      v-if="!loading && !searchResult.items.length"
      class="u-text-align--center u-p--m"
    >
      <Icon
        :icon="iconSearch"
        :size="64"
        class="u-text-light-gray"
      />

      <div
        class="u-text-dark-gray u-mt--s"
        v-html="translations.noResultHtml"
      />
    </div>

    <!-- Loading state -->
    <div
      v-if="loading"
      class="u-flex u-p--l u-flex-align-center u-flex-justify-center u-height-min-50vh"
    >
      <div class="c-spinner c-spinner--lg" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import Icon from '@/components/Icon.vue'
import RecipeGrid from '@/components/search/RecipeGrid.vue'
import ProductGrid from '@/components/search/ProductGrid.vue'
import iconSearch from '@/assets/img/icons/search.svg'
import searchFactory from '@/composition/search/searchFactory'
import searchQueryState from '@/composition/search/searchQuery'
import searchConfig from '@/composition/search/searchConfig'

export default {
  components: { Icon, RecipeGrid, ProductGrid },
  props: {
    initialSearchResult: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { loading, allItemsLoaded } = searchFactory.useSearchResult(props.initialSearchResult)
    const { searchConfiguration } = searchConfig.searchConfigurationUse()
    const {
      hasExtendedFilters, searchType, searchResult, searchMoreResults
    } = searchQueryState.searchQueryUse()

    return {
      hasExtendedFilters,
      gridComponent: computed(() => `${searchType.value}Grid`),
      searchResult,
      searchMoreResults,
      loading,
      allItemsLoaded,
      iconSearch,
      translations: computed(() => searchConfiguration.translations)
    }
  }
}
</script>
