import { computed, reactive } from 'vue'
import searchFactoryState from './searchFactory'

const searchQueryState = reactive({
  take: 16,
  page: 1,
  terms: [],
  searchType: null,
  activeCategories: [],
  language: null,
  updated: Date.now(),
  initialized: false
})

export default {
  searchQueryUse(routeData) {
    const state = searchQueryState
    const { search, searchMore, searchResult } = searchFactoryState.useSearchResult()

    const incrementPage = () => {
      state.page += 1
    }

    const resetPageNumber = () => {
      state.page = 1
    }

    const doSearch = () => {
      resetPageNumber()
      search(state)
    }

    const searchMoreResults = () => {
      incrementPage()
      searchMore(state)
    }

    if (!state.initialized && routeData) {
      state.activeCategories = routeData.activeCategories
      state.searchType = routeData.searchType
      state.terms = routeData.terms
      state.language = routeData.language
      state.initialized = true
    }

    const addCategory = (category) => {
      const index = state.activeCategories
        .map((activeCategory) => activeCategory.slug)
        .indexOf(category.slug)
      if (index === -1) {
        state.activeCategories.unshift(category)
        doSearch()
      }
    }

    const removeCategory = (category) => {
      const index = state.activeCategories
        .map((activeCategory) => activeCategory.slug)
        .indexOf(category.slug)
      if (index > -1) {
        state.activeCategories.splice(index, 1)
        doSearch()
      }
    }

    const setTerms = (terms = []) => {
      state.terms = terms
      doSearch()
    }

    const addTerm = (term) => {
      if (state.terms.indexOf(term) !== -1) {
        return
      }

      state.terms.unshift(term)
      doSearch()
    }

    const removeTerm = (term) => {
      const index = state.terms.indexOf(term)
      if (index > -1) {
        const tempArray = state.terms
        tempArray.splice(index, 1)
        setTerms(tempArray)
      }
    }

    const resetAllFilters = () => {
      state.terms = []
      state.activeCategories = []
      doSearch()
    }

    return {
      searchQuery: state,
      searchResult,
      activeCategories: computed(() => state.activeCategories),
      activeTerms: computed(() => state.terms),
      language: computed(() => state.language),
      searchType: computed(() => state.searchType),
      hasExtendedFilters: computed(() => state.searchType === 'Recipe'),
      addCategory,
      removeCategory,
      setTerms,
      addTerm,
      removeTerm,
      resetAllFilters,
      searchMoreResults
    }
  }
}
