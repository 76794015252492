<template>
  <div v-click-away="hideDropdown">
    <div class="c-recipe-ingredients__header">
      <div class="c-recipe-ingredients__portions">
        <button
          class="c-recipe-ingredients__portions-button"
          data-gtm-trigger="click"
          :disabled="activeIndex === 0"
          @click="performStep(activeIndex - 1, 'subtract')"
        >
          <Icon
            :icon="iconSubtract"
            :size="20"
          />
          <span class="u-hide-on-screenonly">
            {{ model.ingredients.increasePortionButtonName }}
          </span>
        </button>
        <div class="c-recipe-ingredients__portions-label">
          <button
            @click="showDropdown"
          >
            {{ portions }} {{ amountText }}
          </button>
          <select
            class="c-portion-selector__dropdown"
            @change="updatePortionValueFromSelect"
          >
            <option
              v-for="(option, index) in model.ingredients.options"
              :key="option.value"
              :value="index"
              :selected="option.text === selectedPortion.text"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
        <button
          class="c-recipe-ingredients__portions-button"
          data-gtm-trigger="click"
          :disabled="activeIndex === optionsCount - 1"
          @click="performStep(activeIndex + 1, 'add')"
        >
          <Icon
            :icon="iconAdd"
            :size="20"
          />
          <span class="u-hide-on-screenonly">
            {{ model.ingredients.decreasePortionButtonName }}
          </span>
        </button>
        <ul
          v-if="isDropdownShown"
          class="c-portion-selector__options u-bare-list"
        >
          <li
            v-for="(option, index) in model.ingredients.options"
            :key="option.value"
            class="c-portion-selector__option"
          >
            <button
              :class="{
                'is-selected': option.text === selectedPortion.text
              }"
              @click.prevent="updatePortionValue(index)"
            >
              {{ option.text }}
            </button>
          </li>
        </ul>
      </div>
      <p
        v-if="model.ingredients.portionInfoText"
        class="u-font-size-large u-m--0"
      >
        {{ model.ingredients.portionInfoText }}
      </p>
    </div>

    <p
      v-if="isScaled && model.ingredients.warningText"
      class="u-font-size-large u-mt--s u-mb--0"
    >
      {{ model.ingredients.warningText }}
    </p>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { directive } from 'vue3-click-away'
import Icon from '@/components/Icon.vue'
import iconAdd from '@/assets/img/icons/add.svg'
import iconSubtract from '@/assets/img/icons/subtract.svg'
import ingredientScaleHelper from '@/composition/recipeIngredientsScaleHelper'
import gtmUtil from '@/utils/gtm-util'

export default {
  directives: {
    ClickAway: directive
  },
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { model } = props
    const {
      state,

      setPortions
    } = ingredientScaleHelper(model)
    const {
      ingredients: {
        options
      }
    } = model
    const portions = computed(() => state.portions)
    const defaultPortion = state.portions
    const isScaled = computed(() => portions.value !== defaultPortion)
    const isDropdownShown = ref(false)

    const optionsCount = options?.length || 0
    const activeIndex = ref(options.findIndex(({ value }) => value === defaultPortion))
    const selectedPortion = computed(() => options.find(({ value }) => value === portions.value))
    const amountText = computed(() => state.amountText)

    const performStep = (index, type) => {
      if (index >= 0 && index < optionsCount) {
        activeIndex.value = index
        const newValue = options[index].value
        setPortions(newValue)
        gtmUtil.scaleRecipePortions(`${newValue} ${amountText.value}`, type)
      }
    }

    const hideDropdown = () => {
      isDropdownShown.value = false
    }

    const showDropdown = () => {
      isDropdownShown.value = true
    }

    const updatePortionValue = (index) => {
      hideDropdown()
      performStep(index, 'dropdown')
    }

    const updatePortionValueFromSelect = (event) => {
      const index = event.target.value
      performStep(index, 'dropdown')
    }

    return {
      activeIndex,
      optionsCount,
      portions,
      iconAdd,
      iconSubtract,
      isScaled,
      isDropdownShown,
      selectedPortion,
      amountText,

      showDropdown,
      hideDropdown,
      performStep,
      updatePortionValue,
      updatePortionValueFromSelect
    }
  }
}
</script>
